import { createSlice } from '@reduxjs/toolkit'

const isTouringFromSession = sessionStorage.getItem('isTouring')
const initialIsTouring = isTouringFromSession !== null ? JSON.parse(isTouringFromSession) : true

const spaceWeather = createSlice({
  name: 'weather',
  initialState: { isTouring: initialIsTouring, isDragging: false, data: null, dateHighlight: 'LIVE', dateGroups: null },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setDateGroups: (state, action) => {
      state.dateGroups = action.payload
    },
    setIsTouring: (state, action) => {
      state.isTouring = action.payload
    },
    setIsDragging: (state, action) => {
      state.isDragging = action.payload
    },
    setDateHighlight: (state, action) => {
      state.dateHighlight = action.payload
    },
  },
})

export const { setIsTouring, setIsDragging, setData, setDateHighlight, setDateGroups } = spaceWeather.actions
export default spaceWeather.reducer
